import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Offcanvas, Container } from 'react-bootstrap';
import Logo from '../Medias/Logos/logo-cap-dark.png';

const MainMenu = ({ contents }) => {
  const expand = 'lg';
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const openOffcanvas = () => {
    console.log("openOffcanvas");
    setShowOffcanvas(true);
  }

  const closeOffcanvas = () => {
    console.log("closeOffcanvas");
    setShowOffcanvas(false);
  }

  const scrollToTarget = (v_target) => {
    var target = document.getElementById(v_target);
    window.scrollTo({
      top: target.offsetTop - 125,
      behavior: 'smooth'
    });
    closeOffcanvas();
  }

  return (
    <>
      <Navbar expand={expand} id="main-menu" fixed='top' bg='white'>
        <Container fluid>
          <Navbar.Brand as={Link} to="/">
            <img src={Logo} className="logo mx-3" alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} onClick={openOffcanvas} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${expand}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
            placement="end"
            show={showOffcanvas}
            onHide={closeOffcanvas}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                <img src={Logo} className="logo mx-3" alt="Logo" />
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              {contents?.routePath?.currentPath === '/' || contents?.routePath?.currentPath === '' ? (
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link onClick={closeOffcanvas} className="fs-5 text-black px-3" as={Link} to={'/'}>Home</Nav.Link>
                  <Nav.Link onClick={() => scrollToTarget('practice')} className="fs-5 text-black px-3">Practice Areas</Nav.Link>
                  <Nav.Link onClick={() => scrollToTarget('vision')} className="fs-5 text-black px-3">Our Vision</Nav.Link>
                  <Nav.Link onClick={() => scrollToTarget('article')} className="fs-5 text-black px-3">News & Content</Nav.Link>
                  <Nav.Link onClick={() => scrollToTarget('client')} className="fs-5 text-black px-3">Clients</Nav.Link>
                  <Nav.Link onClick={() => scrollToTarget('contact')} className="fs-5 text-black px-3">Contact</Nav.Link>
                  <Nav.Link className="fs-5 tx-color-secondary px-3" href={'/cn'}>中國人</Nav.Link>
                  <Nav.Link className="fs-5 tx-color-secondary px-3" href={'/de'}>Deutsch</Nav.Link>
                </Nav>
              ) : (
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link onClick={closeOffcanvas} className="fs-5 text-black px-2" as={Link} to={'/'}>Home</Nav.Link>
                  <Nav.Link onClick={() => scrollToTarget('practice')} className="fs-5 text-black px-2" as={Link} to={'/#practice'}>Practice Areas</Nav.Link>
                  <Nav.Link onClick={() => scrollToTarget('vision')} className="fs-5 text-black px-2" as={Link} to={'/#vision'}>Our Vision</Nav.Link>
                  <Nav.Link onClick={() => scrollToTarget('article')} className="fs-5 text-black px-2" as={Link} to={'/#article'}>News & Content</Nav.Link>
                  <Nav.Link onClick={() => scrollToTarget('client')} className="fs-5 text-black px-2" as={Link} to={'/#client'}>Clients</Nav.Link>
                  <Nav.Link onClick={() => scrollToTarget('contact')} className="fs-5 text-black px-2" as={Link} to={'/#contact'}>Contact</Nav.Link>
                  <Nav.Link className="fs-5 tx-color-secondary px-2" href={'/cn'}>中國人</Nav.Link>
                  <Nav.Link className="fs-5 tx-color-secondary px-2" href={'/de'}>Deutsch</Nav.Link>
                </Nav>
              )}
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  )
}

MainMenu.defaultProps = {
  contents: {
    routePath: {
      currentPath: '/'
    }
  }
}

export default MainMenu