import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import FormContact from '../Components/FormContact';
import ObjSocialList from './ObjSocialList';

const SectionContact = ({ contents }) => {
  return (
    <>
      <section id="contact" className="pt-5 pb-5">
        <Container className="tx-color-primary mb-5">
          <div className="fs-1 mb-5 text-center font-header"><span className='tx-color-secondary font-header'>Contact</span> Us</div>
          <Row className='text-center'>
            <Col md={12} lg={4} className="px-2 px-md-5">
              <div className='fs-4 mb-3 mb-md-5 mt-0 mt-md-0 fw-bold'>Address</div>
              <p className="mb-2">{contents?.contact_lang?.["address-office"]}</p>
              <p>{contents?.contact_lang?.address}</p>
            </Col>
            <Col md={12} lg={4} className="px-2 px-md-5">
              <div className='fs-4 mb-3 mb-md-5 mt-5 mt-md-0 fw-bold'>Channel</div>
              <p className="mb-0">{contents?.contact?.phone}</p>
              <p className="mb-2">{contents?.contact?.email}</p>
            </Col>
            <Col md={12} lg={4} className="px-2 px-md-5">
              <div className='fs-4 mb-3 mb-md-5 mt-5 mt-md-0 fw-bold'>Work Hours</div>
              <p className="mb-0">{contents?.contact?.["work-day"]}</p>
              <p className="mb-0">{contents?.contact?.["work-hours"]}</p>
            </Col>
          </Row>
        </Container>
        <Container fluid className='mb-5 pb-5'>
          <Row>
            <Col md={12} lg={6} className="p-0 px-2 px-md-0">
              <iframe src={contents?.contact?.["google-maps"]} width="100%" height="500" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="googlemaps"></iframe>
            </Col>
            <Col md={12} lg={5} className="px-2 px-md-5 pt-4">
              <h3 className="font-header tx-color-primary mb-4">Get in Touch for Legal Inquiries</h3>
              <p className='tx-color-primary'>As one of the top international law firms in Thailand, Aber Group stands ready to provide our clients with comprehensive, up-to-date legal solutions for their business interests. Our legal team will respond to all inquiries and concerns within 1-3 business days.</p>
              <FormContact lang={contents.lang} />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default SectionContact