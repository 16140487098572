import React from 'react';
import Bg from '../Medias/mockup/bg.png';

function SectionHeader({ topic, detail, textAlign, textColor, bg }) {
  return (
    <>
      <section
        className="section-header position-relative tx-color-primary font-header bg-img"
        style={{ height: '30rem', backgroundImage: `url(${Bg})` }}
      >
        <div className='position-absolute w-100 h-100 top-0 left-0' style={{ backgroundColor: 'rgba(255, 255, 255, 0.6)' }}></div>
        <div className='position-absolute top-50 start-0 w-100 text-center'>
          <div className="fs-1 text-uppercase tx-spacing-30 mt-0 px-0 px-md-5">{topic}</div>
        </div>
      </section>
    </>
  )
}

SectionHeader.defaultProps = {
  topic: '',
  detail: '',
  textAlign: 'top-50 start-50 text-center',
  textColor: 'text-white',
  bg: 'radial-gradient(circle at 0% 0%, #1B2252, #00423E 80%)'
}

export default SectionHeader