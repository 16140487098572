import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Logo from '../Medias/Logos/logo-cap-white.png';
import ObjSocialList from './ObjSocialList';

const MainFooter = ({ contents }) => {
  const scrollToTarget = (v_target) => {
    var target = document.getElementById(v_target);
    window.scrollTo({
      top: target.offsetTop - 125,
      behavior: 'smooth'
    });
  }

  return (
    <footer id="footer" className="pt-5 pb-2 bg-color-primary">
      <Container fluid className="text-center">
        <Row>
          <Col>
            <img src={Logo} alt="Logo" className="logo mb-4" />
          </Col>
        </Row>
        <Row>
          <Col>
            {contents?.routePath?.currentPath === '/' || contents?.routePath?.currentPath === '' ? (
              <ul className="p-0 m-0 mb-4">
                <li className="d-inline-block mx-4">
                  <Link to="/" className="text-white text-decoration-none">Home</Link>
                </li>
                <li className="d-inline-block mx-4">
                  <Link onClick={() => scrollToTarget('practice')} className="text-white text-decoration-none">Practice Areas</Link>
                </li>
                <li className="d-inline-block mx-4">
                  <Link onClick={() => scrollToTarget('vision')} className="text-white text-decoration-none">Our Vision</Link>
                </li>
                <li className="d-inline-block mx-4">
                  <Link onClick={() => scrollToTarget('article')} className="text-white text-decoration-none">News & Content</Link>
                </li>
                <li className="d-inline-block mx-4">
                  <Link onClick={() => scrollToTarget('client')} className="text-white text-decoration-none">Clients</Link>
                </li>
                <li className="d-inline-block mx-4">
                  <Link onClick={() => scrollToTarget('contact')} className="text-white text-decoration-none">Contact</Link>
                </li>
              </ul>
            ) : (
              <ul className="p-0 m-0 mb-4">
                <li className="d-inline-block mx-4">
                  <Link to="/" className="text-white text-decoration-none">Home</Link>
                </li>
                <li className="d-inline-block mx-4">
                  <Link to="/" className="text-white text-decoration-none">Practice Areas</Link>
                </li>
                <li className="d-inline-block mx-4">
                  <Link to="/" className="text-white text-decoration-none">Our Vision</Link>
                </li>
                <li className="d-inline-block mx-4">
                  <Link to="/" className="text-white text-decoration-none">News & Content</Link>
                </li>
                <li className="d-inline-block mx-4">
                  <Link to="/" className="text-white text-decoration-none">Clients</Link>
                </li>
                <li className="d-inline-block mx-4">
                  <Link to="/" className="text-white text-decoration-none">Contact</Link>
                </li>
              </ul>
            )}
          </Col>
        </Row>
        <Row className='mt-5'>
          {/* <Col className="text-center text-md-start">
            <ul className="p-0 m-0">
              <li className="d-inline-block me-2">
                <Link to="/PrivacyPolicy" className="text-white fw-light">นโยบายความเป็นส่วนตัว</Link>
              </li>
              <li className="d-inline-block me-2">
                <Link to="/CookiesPolicy" className="text-white fw-light">นโยบายคุ้กกี้</Link>
              </li>
            </ul>
          </Col> */}
          <Col className="text-center text-white">&copy;2024 by aberthailand</Col>
        </Row>
      </Container>
    </footer>
  )
}

MainFooter.defaultProps = {
  contents: {
    routePath: {
      currentPath: '/'
    }
  }
}

export default MainFooter