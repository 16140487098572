import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Photo1 from '../Images/mockup/partner-1.png';
import Photo2 from '../Images/mockup/partner-2.png';
import Photo3 from '../Images/mockup/partner-3.png';
import Photo4 from '../Images/mockup/partner-4.png';
import Photo5 from '../Images/mockup/partner-5.png';
import Photo6 from '../Images/mockup/partner-6.png';
import Photo7 from '../Images/mockup/partner-7.png';
import Photo8 from '../Images/mockup/partner-8.png';
import { urlApi } from '../Constants/Global';

const SectionPartner = ({ contents }) => {
  return (
    <>
      <section id="client">
        <Container className='mt-5 mb-5'>
          <Row>
            <Col className="p-5 px-0 px-md-5 text-center" sm={{ span: 12, offset: 0 }} md={{ span: 10, offset: 1 }}>
              {contents.map((item, index) => (
                <img src={`${urlApi}/storage/media/${item.set1}`} alt="Partner" className='mx-3' style={{ maxWidth: '140px' }} key={index} />
              ))}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

SectionPartner.defaultProps = {
  contents: []
}

export default SectionPartner