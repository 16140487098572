import React, { useState } from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { urlApi } from '../Constants/Global';
import NoPhoto from '../Medias/LibImages/no-image.svg';

function SectionNews({ contents }) {
  const minPercentage = 80;
  const maxPercentage = 160;
  const [selectCategory, setSelectCategory] = useState('');

  const handleClick = (sel_item) => {
    console.log("handleClick", sel_item);
    setSelectCategory(sel_item);
  }

  return (
    <section id="article" className="pt-5 pb-5">
      <Container className='pt-5 pb-5'>
        <>
          <h1 className='font-header text-center mb-5'><span className='font-header tx-color-secondary'>News</span> & Content</h1>
          <Row>
            {contents.map((item, index) => (
              <Col sm={12} md={4}>
                <Link to={`/Article/${item.row_slug}`} className='text-white text-decoration-none'>
                  <Card className='w-100 border-0 rounded-0 mb-5'>
                    <div className="bg-img ratio-16x9" style={{ backgroundImage: `url(${item.row_value !== null ? `${urlApi}/storage/article/${item.row_value}` : NoPhoto})` }}></div>
                    <Card.Title className='font-default w-100 p-3 px-4 m-0 fw-normal tx-color-primary'>
                      {item.name}
                    </Card.Title>
                  </Card>
                </Link>
              </Col>
            ))}
          </Row>
          <Row className='mt-5'>
            <Col className='text-center'>
              <Button className='font-header bg-color-secondary border-0 text-white px-4 fs-4'>Show More</Button>
            </Col>
          </Row>
        </>
      </Container>
    </section >
  )
}

SectionNews.defaultProps = {
  contents: []
}

export default SectionNews