import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import Logo from '../Images/logo/logo_s-wt.png';

const MainCopyright = () => {
  return (
    <>
      <section id="copyright" className="bg-color-black text-white pt-2 pb-2">
        <Container fluid>
          <Row>
            {/* <Col className="text-center text-md-start">
              <ul className="p-0 m-0">
                <li className="d-inline-block me-2">
                  <Link to="/PrivacyPolicy" className="text-white fw-light">Privacy Policy</Link>
                </li>
                <li className="d-inline-block me-2">
                  <Link to="/CookiesPolicy" className="text-white fw-light">Cookies Policy</Link>
                </li>
              </ul>
            </Col> */}
            <Col className="text-center">&copy;2024 by aberthailand.com </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

MainCopyright.defaultProps = {

}

export default MainCopyright