import React from 'react';
import { urlApi } from '../Constants/Global';
import ContentDisplay from '../Components/ContentDisplay';

import SectionCarousel from '../Components/SectionCarousel';
import SectionContact from '../Components/SectionContact';
import Logo from '../Medias/Logos/logo-cap-dark.png';
import Bg from '../Medias/mockup/bg.png';
import SectionPractice from '../Components/SectionPractice';
import SectionVision from '../Components/SectionVision';
import SectionNews from '../Components/SectionNews';
import SectionPartner from '../Components/SectionPartner';

const Home = ({ contents }) => {
  console.log(contents)
  const storage = urlApi + '/storage/article/';

  const slides = [
    {
      row_value: Bg,
      detail:
        <>
          <h1 className='tx-color-secondary font-header fw-normal mb-0' style={{ lineHeight: 1.1 }}>Pioneering</h1>
          <h1 className='tx-color-primary font-header fw-normal' style={{ lineHeight: 1.1 }}>Global Legal<br />Excellence</h1>
          {/* <p className='tx-color-primary font-default'>ABER is the FIRST law firm legally accepting cryptocurrencies in Thailand.</p> */}
        </>
    }
  ];

  return (
    <>
      {/* <ContentDisplay contents={contents?.content?.contents} storage={storage} lang={contents.lang} /> */}
      <SectionCarousel contents={slides} />
      <SectionPractice contents={contents?.web_setup?.practice} topic={contents?.web_setup?.practice_topic} />
      <SectionVision contents={contents?.web_setup?.vision} />
      <SectionNews contents={contents?.articles} />
      <SectionPartner contents={contents?.web_setup?.client} />
      <SectionContact contents={contents} />
    </>
  )
}

Home.defaultProps = {
  contents: {
    lang: 'th',
    content: {
      contents: []
    }
  },
  web_setup: {
    practice_topic: [],
    practice: []
  },
  path: '',
  lang: 'en'
}

export default Home